<template>
  <div>
    <h1 class="header">.conversations</h1>
    <div class="flex-container">
      <div v-for="(interview, index) in allinterviews" :key="index" @click="navigateInterview(interview)" class="item grow">
        <div class="item-header">
          <p class="title">{{interview.title}}</p>
        </div>
          <img :src="interview.coverPhotoUrl" class="image"/>
      </div>
    </div>
  </div>
</template>

<script>
import sanity from "../client";
import router from "../router";

export default {
  data() {
    return {
      interviewsResponse: null,
      openItem: null,
      carouselIndex: 0
    }
  },
  async mounted() {
    const query = `*[_type == 'interview' && !(_id in path("drafts.**"))]{"imageUrls": images[].asset->url, "coverPhotoUrl": cover_photo.asset->url, ...}|order(orderRank)`
    let response = await sanity.fetch(query)
    this.interviewsResponse = response
  },
  methods: {
    navigateInterview(interview) {
      router.push(`conversations/${interview._id}`)
    },
    onCarouselBtn(forward) {
      if(forward) {
        this.carouselIndex = (this.carouselIndex + 1) % this.openItem.imageUrls.length
      } else {
        this.carouselIndex = (this.openItem.imageUrls.length + (this.carouselIndex - 1)) % this.openItem.imageUrls.length
      }
      
    },
    closeDialog() {
      this.openItem = null
      this.carouselIndex = 0
    }
  },
  computed: {
    allinterviews() {
      return this.interviewsResponse ? this.interviewsResponse : []
    }
  }
}
</script>

<style scoped>
.header {
  margin-left: 15px;
}
.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /* align-items: center; */
}
.item {
  margin: 5px 10px;
  cursor: pointer;
}
.image {
  height: 400px;
  width: 400px;
  object-fit: cover;
  object-position: 25% 25%; 
}
@media screen and (max-width: 600px) {
  .image {
    height: 300px;
    width: 300px;
  }
  .large-image {
    width: 90vw;
  }
}

.grow { 
transition: all .2s ease-in-out; 
}

.grow:hover { 
transform: scale(1.05); 
}

.title {
  grid-column: 1;
  margin: 0px;
}
.arrow-icon {
  color: white;
  font-size: 30px;
  margin-top: 10px;
  margin-left: 10px;
}
.image-dialog-container {
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
  justify-content: center;
}
.dialog {
  position: fixed; /* Stay in place */
  z-index: 0; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
  backdrop-filter: blur(3px);
}
.dialog-content {
  width: 80vw;
  top: 10%;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
}
.large-image {
  max-height: 80vh;
}
.interview-description {
  color: white;
  position: absolute;
}
.carousel-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: black;
  position: absolute;
  top: calc(50% - 25px);
  z-index: 10;
  opacity: 0.3;
  cursor: pointer;
  margin-left: 10px;

}
.right-btn {
  right: 10px
}
.close-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: black;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  opacity: 0.3;
  cursor: pointer;
}
.close-icon {
  font-size: 20px;
  color: white;
  margin-top: 5px;
  margin-left: 5px;
}
</style>