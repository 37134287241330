import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Photography from '../views/Photography.vue'
import Videos from '../views/Videos.vue'
import Music from '../views/Audio.vue'
import DJ from '../views/Dj.vue'
import Painting from "../views/Paiting.vue"
import Interview from "../views/Interview.vue"
import InterviewDetail from "../views/InterviewDetail.vue"


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/jpeg',
    name: 'Photography',
    component: Photography
  },
  {
    path: '/gallery',
    name: 'Painting',
    component: Painting
  },
  {
    path: '/mp4',
    name: 'Videos',
    component: Videos
  },
  {
    path: '/mp3',
    name: 'MP3',
    component: Music
  },
  {
    path: '/dj',
    name: 'DJ',
    component: DJ
  },
  {
    path: '/conversations',
    name: 'Conversations',
    children: [
      { path: '', component: Interview },
      { path: ':id', component: InterviewDetail },
    ]
  }
]

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
