<template>
  <div class="interview-detail-container">
    <h1 class="interview-header" @click="handleHeadingClick">.conversations</h1>
    <h1>{{ interview.title }}</h1>

    <PortableText
     v-if="interview"
        :value="interview.interviewBody"
        :components="components"
    />
   
  </div>
</template>

<script>
import sanity from "../client";
import { PortableText } from '@portabletext/vue';
import router from "../router";
import { h } from 'vue'

export default {
  data() {
    return {
      interviewsResponse: null,
      openItem: null,
      carouselIndex: 0,
      id: null,
      components: {
        types: {
          image: ({ value }) => {
            return h('img', {
              src: value._image_url,
              alt: value.alt || ' ',
              loading: 'lazy',
              style: {
                // display: 'inline-block'
                display: 'block',
                // Avoid jumping around with aspect-ratio CSS property
                aspectRatio: 1,
                margin: 'auto',
                maxHeight: '300px',
                width: 'auto'
              },
            });
          },
          // Any other custom types you have in your content
        },
        marks: {
          em: (_, { slots }) => h('em', { class: 'font-semibold' }, slots.default?.()),
          strong: (_, { slots }) => h('b', { style: 'font-weight: 600' }, slots.default?.()),
        }
      }
    }
  },
  components: {
    PortableText: PortableText
  },
  async mounted() {
    this.id = router.currentRoute._value.params.id

    const query = `*[_type == 'interview' && _id == '${this.id}']{
    "imageUrl": interviewBody[].asset->url,
    interviewBody[]{
      ...,
      _type == "image" => { 
        'url': @->url
      },
      _type == "image" => { 
        'url': "this is a test"
      },
    },
     ...}`
    let response = await sanity.fetch(query)
    this.interviewsResponse = response
  },
  methods: {
    onCarouselBtn(forward) {
      if(forward) {
        this.carouselIndex = (this.carouselIndex + 1) % this.openItem.imageUrls.length
      } else {
        this.carouselIndex = (this.openItem.imageUrls.length + (this.carouselIndex - 1)) % this.openItem.imageUrls.length
      }
      
    },
    closeDialog() {
      this.openItem = null
      this.carouselIndex = 0
    },
    handleHeadingClick() {
      this.$router.push('/conversations')
    }
  },
  computed: {
    interview() {
      if(this.interviewsResponse) {
        for(let i in this.interviewsResponse[0].interviewBody) {
          let block = this.interviewsResponse[0].interviewBody[i]
          if(block._type === 'image') {

            block['_image_url'] = this.interviewsResponse[0].imageUrl[i]
          }
        }
      }
      return this.interviewsResponse ? this.interviewsResponse[0] : []

    }
  }
}
</script>

<style scoped>
.header {
  margin-left: 15px;
}
.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.item {
  margin: 5px 10px;
  cursor: pointer;
}
.image {
  height: 400px;
  width: 400px;
  object-fit: cover;
  object-position: 25% 25%; 
}
@media screen and (max-width: 600px) {
  .large-image {
    width: 90vw;
  }
  .interview-detail-container {
    text-align: center;
    padding-right: 15px !important;
  }

}

.grow { 
transition: all .2s ease-in-out; 
}

.grow:hover { 
transform: scale(1.05); 
}
.title {
  grid-column: 1;
  margin: 0px;
}
.arrow-icon {
  color: white;
  font-size: 30px;
  margin-top: 10px;
  margin-left: 10px;
}
.image-dialog-container {
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
  justify-content: center;
}
.dialog {
  position: fixed; /* Stay in place */
  z-index: 0; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
  backdrop-filter: blur(3px);
}
.dialog-content {
  width: 80vw;
  top: 10%;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
}
.large-image {
  max-height: 80vh;
}
.interview-description {
  color: white;
  position: absolute;
}
.carousel-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: black;
  position: absolute;
  top: calc(50% - 25px);
  z-index: 10;
  opacity: 0.3;
  cursor: pointer;
  margin-left: 10px;

}
.right-btn {
  right: 10px
}
.close-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: black;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  opacity: 0.3;
  cursor: pointer;
}
.close-icon {
  font-size: 20px;
  color: white;
  margin-top: 5px;
  margin-left: 5px;
}

.interview-detail-container {
  padding-bottom: 30px;
  padding-right: 100px;
  padding-left: 15px;
}

.interview-header {
  cursor: pointer;
}
</style>