<template>
  <div>
    <div>
      <!-- <router-link to="/"> -->
        <img src="../assets/eoin_logo.png" class="logo"/>
      <!-- </router-link> -->
    </div>
    <Transition name="slide-fade" >
    <div v-if="showLinks">
      <div class="link-container">
        <router-link to="/mp3">.mp3</router-link>
        <router-link to="/mp4">.mp4</router-link>
        <router-link to="/jpeg">.jpeg</router-link>
        <router-link to="/dj">.dj</router-link>
        <!-- <router-link to="/conversations">.conversations</router-link>
        <router-link to="/gallery">.gallery</router-link> -->
        <router-link to="/about">.about</router-link>
      </div>
      <div class="dark-container">
        <span v-if="darkMode" class="material-icons light-mode">light_mode</span>
        <span v-else class="material-icons dark-mode">dark_mode</span>
        <Toggle @toggleSwitch="handleModeToggle" class="toggle-switch"/>
      </div>
    </div>
    </Transition>
  </div>
</template>

<script>
import Toggle from "./Toggle.vue"

export default {
  components: {
    Toggle
  },
  data() {
    return {
      darkMode: false,
      showLinks: true
    }
  },
  methods: {
    handleModeToggle(active) {
        //Toggle dark mode
        this.darkMode = active
        this.$emit('toggleDark', active)
    },
    // onImageClick() {
    //   this.showLinks = !this.showLinks
    // }
  }
}
</script>

<style scoped>
.logo {
  width: 100px;
  height: 100px;
}
.link-container {
  padding-left: 20px;
}
.link-container a {
  display: block;
  /* margin-bottom: 20px; */
  align-content: left;
}
a {
  color: blue;
  margin-bottom: 10px;
}
a:visited {
  color: blue;
}
.light-mode {
  font-size: 20px;
  grid-column: 1;
  margin-right: 5px;
}
.dark-mode {
  font-size: 20px;
  grid-column: 1;
  margin-right: 5px;
}
.toggle-switch {
  grid-column: 2;
}
.dark-container {
  display: grid;
  grid-template-columns: min-content 50px;
  margin-left: 20px;
}

/* .v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
} */

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  /* transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1); */
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>