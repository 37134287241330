<template>
    <Sidebar v-model:visible="visibleModel">
      <div class="link-container">
        <router-link to="/mp3" @click="closeMenu">.mp3</router-link>
        <router-link to="/mp4" @click="closeMenu">.mp4</router-link>
        <router-link to="/jpeg" @click="closeMenu">.jpeg</router-link>
        <router-link to="/dj" @click="closeMenu">.dj</router-link>
        <!-- <router-link to="/conversations" @click="closeMenu">.conversations</router-link>
        <router-link to="/gallery" @click="closeMenu">.gallery</router-link> -->
        <router-link to="/about" @click="closeMenu">.about</router-link>
      </div>
      <div class="dark-container">
        <span v-if="darkMode" class="material-icons light-mode">light_mode</span>
        <span v-else class="material-icons dark-mode">dark_mode</span>
        <Toggle @toggleSwitch="handleModeToggle" class="toggle-switch"/>
      </div>
    </Sidebar>
  
</template>

<script>
import Sidebar from 'primevue/sidebar';
import Toggle from "./Toggle.vue"

export default {
  components: {
    Sidebar,
    Toggle
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    modelValue: {}
  },
  data() {
    return {
      items: [
        {label: '.info'},
        {label: '.jpeg'},
        {label: '.mp4'},
        {label: '.music'},
        {label: '.dj'}
      ],
      darkMode: false,
      visibleModel: false
    }
  },
  methods: {
    handleModeToggle(active) {
        //Toggle dark mode
        // this.darkMode = active
        this.$emit('toggleDark', active)
    },
    closeMenu() {
      console.log('closeMenu')
      this.visibleModel = false
    }
  },
  watch: {
    visibleModel(newVal) {
      this.$emit('update:modelValue', newVal)
    },
    modelValue(newVal) {
      console.log('visble changed')
      this.visibleModel = newVal
    }
  }
}
</script>

<style scoped>
.toggle-switch {
  grid-column: 2;
}
.dark-container {
  display: grid;
  grid-template-columns: min-content 50px;
  margin-left: 20px;
}
.link-container {
  padding-left: 20px;
}
.link-container a {
  display: block;
  /* margin-bottom: 20px; */
  align-content: left;
}
a {
  color: blue;
  margin-bottom: 10px;
  font-family: career;
}
a:visited {
  color: blue;
}
</style>

<style >
/* .p-sidebar {
  background: #808080 !important;
} */
</style>
